import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialDialogService } from './service/material-dialog.service';
import { MaterialDialogComponent } from './component/material-dialog/material-dialog.component';
import { TranslocoModule } from '@jsverse/transloco';
import { MaterialBarComponent } from './component/material-bar/material-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { ProgressBarChartComponent } from '../charts/progress-bar-chart/component/progress-bar-chart/progress-bar-chart.component';
import { CollapsableCardComponent } from '../components/collapsable-card/collapsable-card.component';
import { ActionIconComponent } from '@futura/futura-ui/button';
import { GhostStateFragmentDirective } from '@futura/futura-ui/ghost';
import { BaseDialogComponent } from '@futura/futura-ui/dialog';

@NgModule({
  declarations: [MaterialDialogComponent, MaterialBarComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    MatIconModule,
    ProgressBarChartComponent,
    BaseDialogComponent,
    CollapsableCardComponent,
    ActionIconComponent,
    GhostStateFragmentDirective,
  ],
  providers: [MaterialDialogService],
})
export class MaterialDialogModule {}
